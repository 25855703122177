const axios = require('axios');

// const url = 'https://api.bjjkwl.com'

const url = process.env.VUE_APP_BASE_API

export const submitInfo = form => {
    return new Promise((resolve, reject) => {
        axios.post(`${url}/contact/user/save`, form).then(res => resolve(res.data))
    })
}