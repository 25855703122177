<template>
  <div class="product" id="product">
    <div :class="`top-container ${topBg}`" id="top_container">
      <header :class="`header ${topNavClass}`">
        <div class="center-wrapper">
          <router-link to="/home" class="logo"><img src="../assets/img/nav_logo.png" alt=""></router-link>
          <nav class="nav">
            <div
              :class="idx === activeNav ? 'active' : ''"
              v-for="(nav, idx) in navs"
              :key="idx"
              @click="navClickHandle(idx, nav)"
            >
              {{ nav.name }}
            </div>
          </nav>
        </div>
      </header>
      <!-- 网络货运监管 -->
      <div class="center-wrapper -content" v-if="activeNav!==3">
        <div class="left-text">
          <img :src="toptext.img_text" alt="" />
          <h1 :style="activeNav===2&&'color: #ebf5fd'">{{toptext.dec}}</h1>
          <div :class="`btn-tell-us ${toptext.btnstyle}`" @click="tellUsHandle"><p>联系我们</p></div>
        </div>
        <img class="ri-img" :src="toptext.right_img" alt="" />
      </div>
      <div class="center-wrapper -content-about" v-else>
        <div class="top-text">
          <h1>京卡物联，物流产业生态数字科技合能者</h1>
          <h2>精耕细作，真正帮助物流企业提高工作效率，降低成本</h2>
        </div>
      </div>

    </div>
    <router-view></router-view>
    <div class="tell-us" v-if="activeNav!==3">
      <div class="center-wrapper map-contnet">
        <div class="text-info">
          <div class="infos">合作邮箱：{{contact.email}}</div>
          <div class="infos">合作电话：{{contact.phone}}</div>
          <div class="infos" style="margin-bottom:0">联系地址：陕西省西安市雁塔区软件新城二期</div>
          <div class="infos" style="padding-left: 170px; margin-top: 5px;">科大讯飞总部二楼</div>
        </div>
        <div class="form-contact">
          <h1>联系我们</h1>
          <el-form label-position="left" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px">
            <el-form-item label="姓名" prop="name">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="联系方式" prop="contactPhone">
              <el-input v-model="ruleForm.contactPhone"></el-input>
            </el-form-item>
            <el-form-item label="简述概况" prop="introduction" class="textarea-form-item">
              <el-input type="textarea" :rows="3" v-model="ruleForm.introduction"></el-input>
            </el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {submitInfo} from "@/api/userinfo";
import {phone,email} from "@/util/constant";
import {Message} from "element-ui";
export default {
  name: "product",
  data() {
    return {
      topNavClass: '',
      contact: {phone,email},
      navs: [
        { name: "网络货运监管服务平台", path: "/mag", bg: "one" },
        { name: "网络货运平台", path: "/net", bg: "two" },
        { name: "厂矿无人值守管理系统", path: "/bobody", bg: "three" },
        { name: "关于我们", path: "/about", bg: "four"}
      ],
      activeNav: 0,
      topBg: "bg-one",
      topContent: [
        {
          dec: "为物流园区，国税局，交通局提供网络货运全方位数据分析与异常数据主动报警，全方位数字化支持网络货运行业发展",
          img_text:  require('../assets/img/top_text.png'),
          right_img: require('../assets/img/bg1_top_right.png'),
          btnstyle: 'btn-one'
        },
         {
          dec: "解决运输业务四流合一问题，提供税务规划一体化服务",
          img_text: require("../assets/img/product2/top_text.png"),
          right_img: require('../assets/img/product2/top_img.png'),
          btnstyle: 'btn-two'
        },
         {
          dec: "实时数据，轻松连接ERP与网络货运系统",
          img_text: require("../assets/img/product3/top_text.png"),
          right_img: require('../assets/img/product3/top_img.png'),
          btnstyle: 'btn-three'
        }
      ],
      toptext: {},
      ruleForm: {
        name: '',
        contactPhone: '',
        introduction: '',
      },
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
        ],
        contactPhone: [
          { required: true, message: '请输入联系方式', trigger: 'blur' }
        ]
      }
    };
  },
  mounted() {
    this.navs.forEach((nav, idx) => {
      if (nav.path && nav.path === this.$route.path) {
        this.activeNav = idx
        this.topBg = `bg-${nav.bg}`
      }
    })
    
    this.toptext = this.topContent[this.activeNav]
  },
  created () {
    document.addEventListener('scroll', this.handleScroll, true);
  },
  beforeDestroy () {
    document.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll () {
        // console.log(window.pageYOffset)
        let top_height = document.getElementById("top_container") && document.getElementById("top_container").scrollHeight || 0
        // console.log(top_height)
        if (window.pageYOffset > top_height) {
          this.topNavClass = 'fixed-nav'
        }else {
          if (window.pageYOffset > 0) {
            this.topNavClass = 'sticky-nav'
          }else {
            this.topNavClass = ''
          }
        }
    },
    navClickHandle(idx, nav) {
      this.activeNav = idx;
      this.topBg = this.navs[idx].bg && `bg-${this.navs[idx].bg}`;
      this.toptext = this.topContent[this.activeNav]
      this.$router.push({ path: nav.path });

      this.resetForm('ruleForm')
    },
    tellUsHandle() {
      // 滚动到底部
      document.body.scrollTop = document.documentElement.scrollTop = document.body.scrollHeight
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.ruleForm)
          submitInfo(this.ruleForm).then(res => {
            if (res.msg === '操作成功') {
              Message({
                message: res.msg,
                type: 'success'
              });
              this.resetForm(formName)
            }else {
              Message({
                message: res.msg,
                type: 'error'
              });
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName] && this.$refs[formName].resetFields();
    }
  }
};
</script>
<style lang="scss">
.product {
  .top-container {
    height: 800px;
    &.bg-one {
      background: url(~@/assets/img/bg1_top.png);
    }
    &.bg-two {
      background: url(~@/assets/img/product2/top_bg.png);
    }
    &.bg-three {
      background: url(~@/assets/img/product3/top_bg.png);
    }
    &.bg-four {
      background: url(~@/assets/img/about/bg_top.png);
    }
    .header {
      height: 80px;
      .center-wrapper {
        display: flex;
        justify-content: space-between;
      }
      &.sticky-nav {
        position: sticky;
        top: 0;
        background: #00000050;
      }
      &.fixed-nav {
        background: #4fb8f8;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        margin:auto;
        z-index:1;
      }
      .logo {
        /* width: 174px;
        height: 37px; */
        margin-top: 21px;
      }
      .nav {
        margin-top: 30px;
        display: flex;
        font-size: 16px;
        color: #fff;
        height: 25px;
        justify-content: space-between;
        flex: 1;
        margin-left: 200px;
        div {
          cursor: pointer;
          &.active {
            border-bottom: 2px solid #fff;
          }
        }
      }
    }
    .-content {
      display: flex;
      padding-top: 130px;
      .left-text {
        flex: 1;
        h1 {
          width: 523px;
          height: 86px;
          font-size: 24px;
          font-family: Heiti SC;
          font-weight: bold;
          color: #d3e9fa;
          line-height: 31px;
          margin-top: 28px;
          margin-left: 20px;
        }
        .btn-tell-us {
          margin-left: 20px;
          cursor: pointer;
          margin-top: 43px;
          &.btn-one {
            width: 220px;
            height: 60px;
            background: linear-gradient(180deg, #4881f3 0%, #0059ec 100%);
            border-radius: 30px;
          }
          &.btn-two {
            width: 220px;
            height: 60px;
            background: linear-gradient(180deg, #9E8BF1 0%, #7F50E0 100%);
            border-radius: 30px;
          }
          &.btn-three {
            width: 220px;
            height: 60px;
            background: rgb(69, 156, 227);
            border-radius: 30px;
          }
          p {
            text-align: center;
            height: 60px;
            font-size: 26px;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            color: #ffffff;
            line-height: 60px;
          }
        }
      }
      .ri-img {
        flex: 1;
        width: 539px;
        height: 470px;
        margin-left: 100px;
      }
    }
    .-content-about {
      .top-text {
        text-align: center;
        margin-top: 130px;
        h1 {
          height: 46px;
          font-size: 48px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #FFFFFF;
          line-height: 60px;
        }
        h2 {
          height: 25px;
          font-size: 25px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #FFFFFF;
          line-height: 39px;
          margin-top: 26px;
        }
      }
    }
  }
  .tell-us {
    width: 100%;
    height: 597px;
    background: #FAFAFA;
    padding-top: 72px;
    .map-contnet {
      display: flex;
      .jmap {
        width: 617px;
        height: 466px;
        padding-right: 41px;
        border-right: 1px solid #DCDCDC;;
      }
      .text-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width:700px;
        height: 466px;
        padding-right: 41px;
        padding-left: 85px;
        border-right: 1px solid #DCDCDC;;
        .infos {
          height: 24px;
          font-size: 24px;
          font-family: Heiti SC;
          font-weight: bold;
          color: #666666;
          line-height: 26px;
          margin-bottom: 26px;
          padding-left: 50px;

          &:nth-child(1) {
            background:  url(~@/assets/img/about/icon_phone.png);
            background-repeat: no-repeat;
          }
          &:nth-child(2) {
            background:  url(~@/assets/img/about/icon_email.png);
            background-repeat: no-repeat;
          }
          &:nth-child(3) {
            background:  url(~@/assets/img/about/icon_addr.png);
            background-repeat: no-repeat;
            height: 40px;
            line-height: 40px;
          }
        }
      }
      .form-contact {
        flex: 1;
        text-align: center;
        margin-top: 20px;
        margin-left: 41px;
        h1 {
          height: 26px;
          font-size: 26px;
          font-family: Heiti SC;
          font-weight: bold;
          color: #666666;
          line-height: 26px;
          margin-bottom: 23px;
        }
      }
    }
  }
}

/* 修改element-ui form的样式  */
input {
  border: none !important;
  background: transparent !important;
  height: 57px !important;
  line-height:57px;
}
.el-textarea__inner {
  border: none !important;
  background: transparent !important;
  margin-top: 12px;
}
.el-form-item__label {
  height: 57px !important;
  line-height: 57px !important;
}
.el-form-item {
  border: 1px solid #DEDEDE;
  height: 57px;
  padding: 0 15px;
  margin-bottom: 40px;
}
.textarea-form-item {
  height: 100px;
}
.el-button--primary {
  float: right;
  width: 147px;
  height: 42px;
  background: #16AAFF;
}
</style>

